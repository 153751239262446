module.exports = [{
      plugin: require('/codebuild/output/src832672131/src/NPR5/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('/codebuild/output/src832672131/src/NPR5/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/codebuild/output/src832672131/src/NPR5/src/cms/cms.js"},
    },{
      plugin: require('/codebuild/output/src832672131/src/NPR5/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-125502801-1"},
    }]
